<template>
  <form
    class="flex flex-col"
    novalidate
    @submit.prevent="handleSubmit"
  >
    <form-global-errors class="mb-5" />

    <div
      class="flex-col"
    >
      <form-group
        :initial-value="bankData?.bank_payee"
        rules="required"
        name="bank_payee"
        class="w-full"
        :placeholder="t('bank.account.form.placeholders.payee_name')"
      />

      <div
        class="flex flex-col sm:flex-row sm:space-x-2"
      >
        <form-group
          name="bank_city"
          rules="required"
          :initial-value="bankData?.bank_city"
          class="w-full sm:w-full"
          type="text"
          :placeholder="t('bank.account.form.placeholders.bank_city')"
        />

        <form-group
          name="bank_country"
          rules="required"
          class="w-full sm:w-90"
          :initial-value="getCountry"
          type="select"
          :form-control-props="{
            options: countries,
          }"
        >
          <template #control-vue-multiselect-option="option">
            <span :class="`flag:${option.value}`" />&nbsp;{{ option.label }}
          </template>

          <template #control-vue-multiselect-singlelabel="option">
            <span :class="`flag:${option.value}`" />&nbsp;{{ option.label }}
          </template>
        </form-group>
      </div>

      <div
        class="flex flex-col sm:flex-row sm:space-x-2"
      >
        <form-group
          name="bank_iban"
          rules="required"
          :initial-value="bankData?.bank_iban"
          class="w-1/3 sm:w-full"
          type="iban"
          :placeholder="t('bank.account.form.placeholders.iban')"
        />

        <form-group
          name="bank_bic"
          rules="required"
          :initial-value="bankData?.bank_bic"
          class="w-1/4 sm:w-90"
          type="bic"
          label="bic"
          :placeholder="t('bank.account.form.placeholders.bic')"
        />
      </div>

      <form-group
        name="bank_name"
        rules="required"
        :initial-value="bankData?.bank_name"
        class="sm:w-full"
        :placeholder="t('bank.account.form.placeholders.bank_name')"
      />

      <form-group
        name="bank_transfer_reason"
        :initial-value="bankData?.bank_transfer_reason"
        class="w-full"
        :placeholder="t('bank.account.form.placeholders.bank_transfer_reason')"
      />
      <app-button
        type="submit"
        :disabled="submitting || invalid"
        :loading="submitting"
        :label="t('common.actions.save')"
        class="sm:mt-4 sm:py-2 sm:px-4 w-max px-4 py-2"
      />
    </div>
  </form>
</template>

<script setup>
import { useI18n } from 'vue-i18n'
import { computed } from 'vue'

import { useAuthStore } from '@shared/store/auth'
import useForm from '@shared/hooks/form/form'
import useGeoloc from '@shared/hooks/geoloc'
import AppButton from '@shared/components/ui/AppButton.vue'
import FormGlobalErrors from '@shared/components/form/FormGlobalErrors.vue'
import FormGroup from '@shared/components/form/FormGroup.vue'

const { countries } = useGeoloc()
const { t } = useI18n()
const authStore = useAuthStore()

const props = defineProps({
  // Bank data
  bankData: {
    type: Object,
    required: false,
    default: null,
  },
})

const emits = defineEmits(['submitted'])

const getCountry = computed(() => (
  props?.bankData?.bank_country ?? authStore.user?.relationships?.address?.attributes?.country
))

const {
  handleSubmit,
  invalid,
} = useForm(props, { emits })

const submitting = false
</script>
